body {
  background-color: #0b0e15;
  color: #fff;
}

.active {
  color: #fff;
  text-decoration: none;
  background: #7e7f82;
  border-radius: 2px;
  box-shadow: inset 2px 2px 0px rgba(255, 255, 255, 0.2),
    inset -2px 2px 0px rgba(0, 0, 0, 0.2);
}

header {
  display: flex;
  .logo {
    a:hover {
      text-decoration: none;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    li {
      float: left;
      a {
        font-size: 0.9rem;
        display: block;
        color: #fff;
        padding: 5px;
        margin-left: 16px;
        transition: all 0.2s ease-in;
        font-weight: 500;
        &:hover {
          color: #fff;
          text-decoration: none;
          background: #7e7f82;
          border-radius: 2px;
          box-shadow: inset 2px 2px 0px rgba(255, 255, 255, 0.2),
            inset -2px 2px 0px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

// Menu
input[type="radio"] {
  -webkit-appearance: none;
  outline: none;
}
@media (max-width: 690px) {
  // body {
  //   background: red;
  // }
  div.slogan {
    font-size: 1.5rem;
    height: 50px;
  }

  #menu {
    // border: 1px solid yellow;
    background: #0b0e15;
    opacity: 0;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    // width: 93%;
    // height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3em 0;
    text-align: center;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    // z-index: 16;
    z-index: -1;
  }

  input[type="radio"]#show:hover,
  input[type="radio"]#hide:hover {
    color: #36a5ec;
    cursor: pointer;
  }
  input[type="radio"]#show,
  input[type="radio"]#hide {
    // border: 1px solid yellow;+
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.5em;
    font-size: 24pt;
    background: #0b0e15;
    color: white;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  #menu ul {
    list-style-type: none;
    line-height: 1.62;
    li {
      float: none;
      a {
        font-size: 1.1rem;
      }
    }
  }

  input[type="radio"]#show {
    z-index: 17;
    opacity: 1;
  }

  input[type="radio"]#hide {
    z-index: -1;
    opacity: 0;
  }

  input[type="radio"]#show:checked ~ input[type="radio"]#hide {
    position: fixed;
    z-index: 17;
    opacity: 1;
  }

  input[type="radio"]#show:checked {
    z-index: -1;
    opacity: 0;
  }

  input[type="radio"]#show::before {
    font-family: FontAwesome;
    content: "\f0c9";
  }

  input[type="radio"]#hide::before {
    font-family: FontAwesome;
    content: "\f00d";
  }

  input[type="radio"]#show:checked ~ #menu {
    z-index: 16;
    animation-name: slideDown;
    -webkit-animation-name: slideDown;
    animation-duration: 0.7s;
    -webkit-animation-duration: 0.7s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    opacity: 1;
  }

  input[type="radio"]#hide:checked ~ #menu {
    animation-name: slideUp;
    -webkit-animation-name: slideUp;
    animation-duration: 0.7s;
    -webkit-animation-duration: 0.7s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    opacity: 0;
  }

  @keyframes slideUp {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  // -end

  #benefits-list li {
    font-size: 1.6rem;
  }
  #miles-flexing img {
    margin: 0 auto;
  }

  .navigation a {
    display: block;
    text-align: center;
    width: 100%;
  }
}

.carousel-inner img {
  margin: auto;
}

.slogan {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  font-size: 3.5rem;
  font-weight: 600;
  background: #262930;
  height: 100px;
  box-shadow: inset 0px 5px 0px rgba(255, 255, 255, 0.1),
    inset 0px -5px 0px rgba(0, 0, 0, 0.1);
  text-shadow: 5px 5px 8px black;
  button {
    font-size: 1rem;
  }
}

.grid {
  .service-types {
    display: flex;
    justify-content: space-around;

    & > div {
      max-width: 240px;
      margin: 0 15px;
      padding: 15px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #49494b;
      border-radius: 5px;
      box-shadow: inset -5px 5px rgba(255, 255, 255, 0.1),
        inset -5px -5px rgba(0, 0, 0, 0.1);
      img {
        width: 185px;
        height: 250px;
        box-shadow: 0px 5px 15px black;
      }
      div {
        font-size: 1.5rem;
      }
    }
  }
  .before-after {
    display: flex;
    align-items: center;
    ul {
      list-style: none;
      list-style-image: url(/images/home-page/before-after/list-style_optimized.png);
      font-size: 2.3rem;
    }
  }
}

footer {
  background: #0c0c14 url(/images/home-page/footer/background.jpg);
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.3), 0px -7px 10px rgba(0, 0, 0, 0.2);
  span.blue {
    color: #36a5ec;
  }

  ul.navigation {
    list-style: none;
    padding: 0;
    font-size: 0.9rem;
    li {
      border-bottom: 2px groove #1a1d24;
      padding: 5px 0;
      a {
        color: #fff;
        transition: all 0.2s ease-in;
        &:hover {
          color: #36a5ec;
          text-decoration: none;
        }
      }
    }
  }

  .copyright {
    text-align: center;
    font-size: 0.8rem;
  }
}

div.animated {
  animation-duration: 2s;
}

h2.heading {
  line-height: 2rem;
  color: #36a5ec;
  text-shadow: 2px 2px 2px black;
}

/**About Page**/
.about-page {
  ul.accomplishments {
    border-top: 2px groove #1a1d24;
    list-style: none;
    padding: 0;
    li {
      font-weight: 500;
      font-size: 1.1rem;
    }
  }
  p.featured {
    font-weight: 500;
    font-style: italic;
    border-top: 2px groove #1a1d24;
    border-bottom: 2px groove #1a1d24;
    color: #36a5ce;
  }
}

/**Contact Page**/
.life-challenges-slogan,
.contact-info {
  border-top: 2px groove #1a1d24;
  border-bottom: 2px groove #1a1d24;
}

/**Testimonials Page**/
.testimonials-page {
  p {
    border-bottom: 2px groove #1a1d24;
  }
}
